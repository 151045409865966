<template>
  <v-data-table
    :headers="headers"
    :items="configs"
    :items-per-page="-1"
    class="dt-listing"
    disable-pagination
    disable-sort
    hide-default-footer
  >
    <template #top>
      <v-toolbar flat>
        <v-toolbar-title v-text="paymentMethod.name" />
        <v-spacer />
        <company-payment-methods-dialog
          :company="company"
          :paymentMethod="paymentMethod"
        />
      </v-toolbar>
    </template>

    <template #[`item.number`]="{ item }">
      <credit-card-preview :number="item.number" />
    </template>

    <template #[`item.bank_name`]="{ item }">
      {{ getBankName(item) }}
    </template>

    <template #[`item.actions`]="{ item }">
      <company-payment-methods-dialog
        :company="company"
        :paymentMethod="paymentMethod"
        :value="item"
      />

      <v-btn color="red" icon text @click="remove(item)">
        <icon-trash size="1.5rem" />
      </v-btn>
    </template>
  </v-data-table>
</template>

<script lang="ts">
import { Component, Mixins, Prop } from "vue-property-decorator";
import DataTableMixin from "@/mixins/DataTableMixin";
import { DataTableHeader } from "vuetify";
import {
  Company,
  PaymentMethod,
  PaymentMethodData,
} from "@planetadeleste/vue-mc-gw";
import CompanyPaymentMethodsDialog from "@/modules/companies/components/CompanyPaymentMethodsDialog.vue";
import CreditCardPreview from "@/components/common/CreditCardPreview.vue";
import { AppModule } from "@/store/app";
import { EventBus } from "@/services/event-bus";
import {
  getTableHeaders,
  InvoicePaymentMethodCode,
} from "@/config/invoicePaymentMethods";
import { get, isNaN, isNil, map, set } from "lodash";
import { InvoicePaymentMethodConfig } from "@/modules/invoices/mixins/InvoiceMixin";
import { number } from "mathjs";
import { PaymentMethodPivotData } from "@/types/utils";

@Component({
  components: { CompanyPaymentMethodsDialog, CreditCardPreview },
})
export default class CompanyPaymentMethodsTable extends Mixins(DataTableMixin) {
  @Prop(Object) readonly company!: Company;
  @Prop(Object) readonly paymentMethod!: PaymentMethod;
  @Prop(Array) readonly items!: PaymentMethodData[];

  headers: DataTableHeader[] = [];

  get type(): InvoicePaymentMethodCode | null {
    // @ts-ignore
    return this.paymentMethod ? this.paymentMethod.code : null;
  }

  get configs() {
    return isNil(this.items)
      ? []
      : map(this.items, (obData: PaymentMethodData) => {
          const obPivot = get(obData, "pivot") as
            | PaymentMethodPivotData
            | undefined;
          if (!obPivot) {
            return {};
          }

          const obConfig = get(obPivot, "config", {});
          set(obConfig, "id", obPivot.id);

          // BANK NAME
          const iBankId = get(obConfig, "bank");
          if (iBankId) {
            const obBank = this.obBankCollection.find({ id: Number(iBankId) });
            if (obBank) {
              set(obConfig, "bank_name", obBank.name);
            }
          }

          return obConfig;
        });
  }

  get obBankCollection() {
    return AppModule.banks;
  }

  mounted() {
    this.$nextTick(async () => {
      this.setHeaders();
      this.addDTActionsHeader();
      this.mapDTHeaders();

      if (this.obBankCollection.isEmpty()) {
        await AppModule.loadBanks();
      }
    });
  }

  async remove(obItem: PaymentMethodData) {
    if (!this.company || !obItem.id) {
      return;
    }

    await this.company.delPaymentMethods({
      id: obItem.id,
      company_id: this.company.id,
      paymentmethod_id: this.paymentMethod.id,
    });

    EventBus.emit("company.update.payment_methods");
  }

  setHeaders() {
    if (!this.type) {
      return;
    }

    const arTableHeaders = getTableHeaders(this.type);
    this.headers = [...this.headers, ...arTableHeaders];
  }

  getBankName(obConfig: InvoicePaymentMethodConfig) {
    const sBank = obConfig.bank_name;

    if (sBank && isNaN(sBank)) {
      return sBank;
    }

    if (!isNaN(sBank)) {
      const obBank = AppModule.banks.find({ id: number(sBank) });
      return obBank ? obBank.name : undefined;
    }

    return undefined;
  }
}
</script>
