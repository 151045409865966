<template>
  <v-card v-if="obCollection.length">
    <v-toolbar color="transparent" flat>
      <v-toolbar-title
        v-text="$t('company.payment.methods.settings', { company: sName })"
      />
    </v-toolbar>

    <div class="gw-tabs">
      <v-tabs v-model="tab" background-color="blue-grey lighten-5" vertical>
        <template v-for="item in items">
          <v-tab :key="`company-pm-${item.id}`">{{ item.name }}</v-tab>
        </template>
      </v-tabs>

      <v-tabs-items v-model="tab" vertical>
        <template v-for="item in items">
          <v-tab-item :key="`company-pm-items-${item.id}`">
            <v-container fluid>
              <v-row>
                <v-col cols="12">
                  <company-payment-methods-table
                    :company="obCompany"
                    :items="getCompanyPaymentMethod(item.code)"
                    :payment-method="item"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-tab-item>
        </template>
      </v-tabs-items>
    </div>
  </v-card>
</template>

<script lang="ts">
import {
  Company,
  FirmData,
  PaymentMethodCollection,
  PaymentMethodData,
} from "@planetadeleste/vue-mc-gw";
import { Component, VModel, Vue } from "vue-property-decorator";
import CompanyPaymentMethodsTable from "@/modules/companies/components/CompanyPaymentMethodsTable.vue";
import { EventBus } from "@/services/event-bus";
import { Dictionary, get } from "lodash";

@Component({
  components: { CompanyPaymentMethodsTable },
})
export default class CompanyPaymentMethods extends Vue {
  @VModel({ type: Object, default: () => new Company() })
  obCompany!: Company;

  tab = 0;
  obCollection: PaymentMethodCollection = new PaymentMethodCollection();
  obCompanyPaymentMethods: Dictionary<
    [PaymentMethodData, ...PaymentMethodData[]]
  > = {};

  get items() {
    return this.obCollection.getModelList() as PaymentMethodData[];
  }

  get obFirm() {
    return this.obCompany.get("firm") as FirmData | undefined;
  }

  get sName() {
    return this.obFirm?.name;
  }

  mounted() {
    this.$nextTick(this.load);
  }

  created() {
    EventBus.on(
      "company.update.payment_methods",
      this.loadCompanyPaymentMethods
    );
  }

  beforeDestroy() {
    EventBus.off(
      "company.update.payment_methods",
      this.loadCompanyPaymentMethods
    );
  }

  async load() {
    this.obCollection.clear();
    this.obCollection.inInvoice().filterBy({ configurable: 1 });

    await this.obCollection.list();
    this.loadCompanyPaymentMethods();
  }

  getCompanyPaymentMethod(sCode: string) {
    return get(this.obCompanyPaymentMethods, sCode);
  }

  async loadCompanyPaymentMethods() {
    if (!this.obCompany) {
      return;
    }

    const obResponse = await this.obCompany.getPaymentMethods();
    if (obResponse) {
      const arItems = obResponse.getData().data;
      //@ts-ignore
      this.obCompanyPaymentMethods = this.$_.groupBy(arItems, "code");
    }
  }
}
</script>
